<template>
  <div class="els-monaco-editor">
    <div
      ref="container"
      class="monaco-editor"
      :style="'height: ' + height + 'px'"
    ></div>
  </div>
</template>
<script>
import * as monaco from "monaco-editor";
export default {
  name: "els-monaco-editor",
  props: {
    dataJson: {
      type: String,
    },
    height: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      //主要配置
      defaultOpts: {
        value: this.dataJson, // 编辑器的值
        theme: "va-dark", //编辑器的主题
        roundeSelection: false, // 右侧不显示编辑器的预览
        autoIndent: true, // 自动缩进
      },
    };
  },
  watch: {
    opts: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 初始化container的内容，销毁之前生成的编辑器
      this.$refs.container.innerHTML = "";
      this.editorOptions = Object.assign(this.defaultOpts, this.opts);
      // 生成编辑器对象
      this.monacoEditor = monaco.editor.create(
        this.$refs.container,
        this.editorOptions
      );
      // 编辑器内容发生改变时触发
      this.monacoEditor.onDidChangeModelContent(() => {
        this.$emit("change", this.monacoEditor.getValue());
      });
    },
    // 供父组件调用手动获取值
    getVal() {
      return this.monacoEditor.getValue();
    },
  },
};
</script>
<style scoped lang="scss">
.els-monaco-editor {
  width: 100%;
  height: 100%;
  .monaco-editor {
    width: 100%;
    height: 160px;
    overflow: hidden;
    border: 1px solid #ddd;
    margin: 10px 0px;
  }
}
</style>